import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Typography,
  Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { RHFSelect } from '#/components/shared/hook-form';
import RepresentativesForm from '../../../../LivingWill/Steps/content/Representatives/RepresentativesForm';
import useLocales from '#/hooks/useLocales';
import { useGetRepresentatives } from '#/api/representativeQueries';
import Iconify from '#/components/shared/ui/Iconify';

type Props = {
  value: string;
  error?: string;
  compare?: string;
};
const Representative = ({ value, error, compare }: Props) => {
  const [lastAdded, setLastAdded] = useState<string | null>(null);

  const [addMore, setAddMore] = useState(false);

  const { watch, setValue } = useFormContext();

  const { data: representatives } = useGetRepresentatives();

  const { translate } = useLocales();

  const handleAddMore = () => {
    setAddMore((prev) => !prev);
  };

  const selectLastAdded = () => {
    if (lastAdded) {
      setValue(value, lastAdded);
    }
  };

  useEffect(() => {
    selectLastAdded();
    // eslint-disable-next-line
  }, [lastAdded]);

  return (
    <Box
      sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      {!addMore && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <RHFSelect
            name={value}
            label={String(translate('global.representative'))}
            placeholder={String('Select representative')}
          >
            <MenuItem
              onClick={() => {
                handleAddMore();
              }}
            >
              <Iconify icon="mdi:account-plus" color="text.secondary" />
              <Typography
                variant="caption"
                sx={{
                  textTransform: 'none',
                }}
              >
                {String(translate('global.addAnother'))}
              </Typography>
            </MenuItem>
            {representatives?.map((rep) => (
              <MenuItem
                key={rep.id}
                value={rep.id}
                disabled={
                  compare && watch(compare) && watch(compare) === rep.id
                }
              >
                {rep.first_name} {rep.last_name}
              </MenuItem>
            ))}
          </RHFSelect>
          {watch(value) && (
            <Button variant="outlined" onClick={() => setValue(value, null)}>
              <Iconify
                icon="tabler:playstation-x"
                sx={{
                  cursor: 'pointer',
                }}
              />
            </Button>
          )}
        </Stack>
      )}
      {addMore && (
        <RepresentativesForm
          handleAddMore={handleAddMore}
          handleLastAdded={setLastAdded}
        />
      )}
      {!!error && addMore && (
        <FormHelperText error={!!error} sx={{ mx: 0 }}>
          {error && error}
        </FormHelperText>
      )}
      {/* {!addMore && ( */}
      {/*  <Button */}
      {/*    variant="contained" */}
      {/*    size="small" */}
      {/*    color="secondary" */}
      {/*    onClick={() => { */}
      {/*      handleAddMore(); */}
      {/*    }} */}
      {/*    sx={{ */}
      {/*      width: '220px', */}
      {/*    }} */}
      {/*    startIcon={ */}
      {/*      <Iconify icon={addMore ? 'mdi:content-save' : 'mdi:plus-circle'} /> */}
      {/*    } */}
      {/*  > */}
      {/*    <Typography variant="caption"> */}
      {/*      {String(translate('global.addAnother'))} */}
      {/*    </Typography> */}
      {/*  </Button> */}
      {/* )} */}
    </Box>
  );
};

export default Representative;
