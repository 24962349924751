import { Box, Button, Stack, Typography } from '@mui/material';
import { PDFViewer, pdf } from '@react-pdf/renderer';

import Confetti from 'react-confetti';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';

import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import useLocales from '../../../../../../lib/hooks/useLocales';
import PowerOfAttorneyDoc from './PowerOfAttorneyDoc';
import useAuth from '#/hooks/useAuth';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';
import { useGetRepresentatives } from '#/api/representativeQueries';
import { useUpdateProfile } from '#/api/userQueries';
import BlurryPurchaseCover from '#/components/shared/ui/BlurryPurchaseCover';
import useDisableDownloads from '#/hooks/useDisableDownloads';
import useHighlightWords from '#/hooks/useHighlightWords';
import useAccountSwitch from '#/hooks/useAccountSwitch';
import { useSubmitDocument } from '#/api/servicesQueries';
import UploadSignedDoc from '#/components/shared/UploadSignedDoc';
import {
  LinkToSettings,
  NoteriseByLawyer,
} from '#/components/pages/PowerAttorney/steps/content/YourPowerOfAttorney/highlight-formats';
import ModalWrapper from '#/components/shared/advisor-helpers/modal-wrapper';
import AdvisorsListView from '#/components/shared/advisor-helpers/partner/advisors-list-view';
import NonPartnerView from '#/components/shared/advisor-helpers/non-partner/non-partner-view';

type Props = {
  outputValues: PowerOfAttorneyFormValues | any;
};

const YourPowerOfAttorney = ({ outputValues }: Props) => {
  const [openAdvisorModal, setOpenAdvisorModal] = useState(false);

  const { user, refetch } = useAuth();
  const { currentAccount } = useAccountSwitch();
  const { translate } = useLocales();
  const { state } = useLocation();
  const highlightWords = useHighlightWords();
  const navigate = useNavigate();

  const { handleDisableDownloads } = useDisableDownloads('power_of_attorney');
  const { mutateAsync: submitDocument, isLoading: submitDoc } =
    useSubmitDocument('powerofattorney');

  const { mutateAsync: updateProfile } = useUpdateProfile();

  const { data: representatives } = useGetRepresentatives();

  const trustedPersonInfo = representatives?.find(
    (representative) => representative.id === outputValues?.trusted_person
  );

  const alternativeTrustedPersonInfo = representatives?.find(
    (representative) => representative.id === outputValues?.alternative_person
  );

  const assetsTrustedPersonInfo = representatives?.find(
    (representative) =>
      representative.id ===
      outputValues?.assets_power_of_attorney?.assets_trusted_person
  );

  const assetsAlternativeTrustedPersonInfo = representatives?.find(
    (representative) =>
      representative.id ===
      outputValues?.assets_power_of_attorney?.alternative_assets_person
  );

  const childrenTrusteeInfo = representatives?.find(
    (representative) =>
      representative.id ===
      outputValues?.children_power_of_attorney?.trusted_person_for_children
  );

  const childrenAlternativeTrusteeInfo = representatives?.find(
    (representative) =>
      representative.id ===
      outputValues?.children_power_of_attorney?.alternative_person_for_children
  );

  const isPartner = user?.partner;

  const handleOpenAdvisorModal = () => setOpenAdvisorModal(true);
  const handleCloseAdvisorModal = () => setOpenAdvisorModal(false);

  const handleContinueProcess = () => {
    setOpenAdvisorModal(false);
    const targetURL =
      'https://app.getyourlawyer.ch/gateway/consultancy?utm_source=gutgeregelt.ch&utm_medium=referral&utm_campaign=may2024';
    window.open(targetURL, '_blank');
    navigate('/dashboard/home');
  };

  // const translatedObligations = Array.from({ length: 2 }, (_, i) =>
  //   translate(`powerOfAttorney.stepTwo.obligation${i + 1}`)
  // );

  const translatedNextSteps = Array.from({ length: 3 }, (_, i) =>
    translate(`powerOfAttorney.stepTwo.nextStep${i + 1}`)
  );

  const generatePDFDocument = async () => {
    const blob = await pdf(
      <PowerOfAttorneyDoc
        outputValues={{
          ...outputValues,
          user: currentAccount,
          trustedPersonInfo,
          assetsTrustedPersonInfo,
          alternativeTrustedPersonInfo,
          assetsAlternativeTrustedPersonInfo,
          childrenTrusteeInfo,
          childrenAlternativeTrusteeInfo,
        }}
      />
    ).toBlob();

    const formData = new FormData();
    formData.append(
      'document',
      blob,
      `${String(
        translate('global.services.powerOfAttorney')
      )}_${currentAccount?.first_name}_${currentAccount?.last_name}_${new Date()
        .toLocaleDateString('en-GB')
        .split('/')
        .join('-')}.pdf`
    );

    await submitDocument(formData)
      .then(() => {
        saveAs(
          blob,
          `${String(
            translate('global.services.powerOfAttorney')
          )}_${currentAccount?.first_name}_${currentAccount?.last_name}_${new Date()
            .toLocaleDateString('en-GB')
            .split('/')
            .join('-')}.pdf`
        );
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.document_submit'))
        );
      });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await updateProfile({
      // eslint-disable-next-line no-unsafe-optional-chaining
      downloaded: ['power_of_attorney'],
    }).then(() => refetch());
  };

  const hideConfetti =
    user?.downloaded?.includes('power_of_attorney') || state?.markAsDone;

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      {!hideConfetti && (
        <Confetti
          recycle={false}
          numberOfPieces={400}
          style={{ width: '100%' }}
        />
      )}
      <Stack
        spacing={2}
        sx={{
          marginBottom: 5,
          width: {
            xs: '100%',
            sm: '100%',
          },
        }}
      >
        <Typography>
          {String(translate('powerOfAttorney.stepTwo.introParagraph'))}
        </Typography>
        <Typography>
          {String(translate('powerOfAttorney.stepTwo.nextStepsTitle'))}
        </Typography>
        {translatedNextSteps.map((nextStep, i) => (
          <Stack
            direction="row"
            alignItems="center"
            key={`key-step${i}`}
            spacing={1}
          >
            <Typography>{i + 1}.</Typography>
            <Typography gutterBottom>
              {i === 0 && (
                <NoteriseByLawyer
                  context={String(nextStep)}
                  targets={[
                    String(
                      translate(
                        'powerOfAttorney.stepTwo.highlightedText.noterizeWithLawyer'
                      )
                    ),
                    'https://gutgeregelt.ch/dashboard/settings',
                  ]}
                  onClick={handleOpenAdvisorModal}
                />
              )}
              {i === 1 && (
                <LinkToSettings
                  context={String(nextStep)}
                  targets={['hier klicken']}
                />
              )}
              {i === 2 && String(nextStep)}
            </Typography>
          </Stack>
        ))}
        <Typography>
          {highlightWords(
            String(translate('powerOfAttorney.stepTwo.helperParagraph3')),
            [String(translate('powerOfAttorney.stepTwo.contactForm'))],
            'kontakt'
          )}
        </Typography>
        <UploadSignedDoc
          serviceName="powerofattorney"
          documentType="powerOfAttorney"
          serviceInvalidateKey="power-of-attorney"
        />
      </Stack>
      {!state?.markAsDone && (
        <Box
          sx={{
            width: {
              xs: '100%',
              sm: '100%',
              md: '50%',
            },
            margin: '0 auto',
          }}
        >
          {handleDisableDownloads() ? (
            <BlurryPurchaseCover
              service="powerOfAttorney.stepTwo.document"
              user={user}
            />
          ) : (
            <PDFViewer
              style={{
                width: '100%',
                height: '550px',
              }}
              showToolbar={false}
            >
              <PowerOfAttorneyDoc
                outputValues={{
                  ...outputValues,
                  user,
                  trustedPersonInfo,
                  assetsTrustedPersonInfo,
                  alternativeTrustedPersonInfo,
                  assetsAlternativeTrustedPersonInfo,
                  childrenTrusteeInfo,
                  childrenAlternativeTrusteeInfo,
                }}
              />
            </PDFViewer>
          )}

          <Button
            disabled={handleDisableDownloads() || submitDoc}
            variant="contained"
            color="primary"
            sx={{ width: 1, mt: 2 }}
            onClick={generatePDFDocument}
          >
            <Typography>{String(translate('global.download'))}</Typography>
          </Button>
        </Box>
      )}
      <ModalWrapper
        open={openAdvisorModal}
        onClose={handleCloseAdvisorModal}
        modalTitle="global.legalAdvisorsModal.title"
        modalDescription={
          isPartner ? '' : `global.legalAdvisorsModal.description`
        }
      >
        {isPartner ? (
          <AdvisorsListView
            advisorType="legal"
            closeModal={handleCloseAdvisorModal}
          />
        ) : (
          <NonPartnerView
            advisorImage="/assets/images/getyourlawyer_logo.svg"
            isComplexForm={false}
            handleContinueProcess={handleContinueProcess}
            isFinancialAdvisor={false}
          />
        )}
      </ModalWrapper>
    </Box>
  );
};

export default YourPowerOfAttorney;
