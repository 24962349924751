import Page from '#/components/shared/ui/Page';
import PowerAttorney from '#/components/pages/PowerAttorney/PowerAttorneyForm';

export default function PowerOfAttorney() {
  return (
    <Page
      title="Vorsorgeauftrag erstellen"
      description="Ohne eine Vorsorgeauftrag können Ihr Ehepartner, Ihre Ehepartnerin oder Ihre Familie selbst in einem Notfall keine Entscheidungen für Sie treffen."
    >
      <PowerAttorney />
    </Page>
  );
}
