import { Document, Page, View, Text } from '@react-pdf/renderer';
import useLocales from '../../../../../../lib/hooks/useLocales';
import styles from '../../../../LivingWill/Steps/content/YourLivingWill/styles';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';
import { fDate } from '#/utils/formatTime';

type Props = {
  outputValues?: PowerOfAttorneyFormValues | any;
};

const PowerOfAttorneyDoc = ({ outputValues }: Props) => {
  const { translate } = useLocales();

  const { user, children_power_of_attorney } = outputValues;

  const address = `${user?.address?.street} ${user?.address
    ?.house_number}, ${user?.address?.zip_code} ${user?.address
    ?.city}, ${String(
    translate(`global.${user?.address?.country.toLowerCase()}`)
  )}`;

  const hasChildren = children_power_of_attorney?.has_children === 'yes';
  const indemnitiesPositive = outputValues?.alternative_compensation === 'yes';

  return (
    <Document
      pageMode="fullScreen"
      title={String(translate('global.services.powerOfAttorney'))}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.headSection}>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.title'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.from'))}
          </Text>
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>
              {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text>
              {String(translate('powerOfAttorney.stepTwo.document.born'))}
              {fDate(user?.date_of_birth, 'dd MMM yyyy', 'de')}
            </Text>
            <Text>
              {String(
                translate('powerOfAttorney.stepTwo.document.civil_status', {
                  civil_status: translate(
                    `powerOfAttorney.stepTwo.document.civilStatus.${user?.civil_status}`
                  ),
                })
              )}
            </Text>
            <Text>
              {String(
                translate('powerOfAttorney.stepTwo.document.address', {
                  address,
                })
              )}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.declare', {
                first_name: user?.first_name,
                last_name: user?.last_name,
              })
            )}
          </Text>
          <View style={styles.pointParagraphs}>
            <Text>1.</Text>
            <View style={styles.pointContent}>
              <Text>
                {String(
                  translate('powerOfAttorney.stepTwo.document.paragraph1')
                )}
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <View style={{ gap: 12 }}>
                  <Text>
                    {String(
                      translate(
                        'powerOfAttorney.stepTwo.document.mainCharacter'
                      )
                    )}
                  </Text>
                  <View style={{ width: '220px' }}>
                    <Text style={styles.underlinedText}>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.assetCare')
                      )}
                    </Text>
                    <Text style={styles.title}>
                      {outputValues?.assetsTrustedPersonInfo?.first_name}{' '}
                      {outputValues?.assetsTrustedPersonInfo?.last_name}
                    </Text>
                    <Text>
                      {String(
                        translate('livingWill.stepFive.document.address', {
                          address: `${outputValues?.assetsTrustedPersonInfo
                            ?.address?.street} ${outputValues
                            ?.assetsTrustedPersonInfo?.address
                            ?.house_number}, ${outputValues
                            ?.assetsTrustedPersonInfo?.address
                            ?.zip_code} ${outputValues?.assetsTrustedPersonInfo
                            ?.address?.city}, ${String(
                            translate(
                              `global.${outputValues?.assetsTrustedPersonInfo?.address?.country.toLowerCase()}`
                            )
                          )}`,
                        })
                      )}
                    </Text>
                    <Text>
                      {String(
                        translate(
                          `powerOfAttorney.stepTwo.document.${
                            outputValues?.assets_power_of_attorney
                              ?.assets_compensation === 'yes'
                              ? 'compensationDeclare'
                              : 'nonCompensationDeclare'
                          }`
                        )
                      )}
                    </Text>
                  </View>
                  <View style={{ width: '220px' }}>
                    <Text style={styles.underlinedText}>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.personCare')
                      )}
                    </Text>
                    <Text style={styles.title}>
                      {outputValues?.trustedPersonInfo?.first_name}{' '}
                      {outputValues?.trustedPersonInfo?.last_name}
                    </Text>
                    <Text>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.address', {
                          address: `${outputValues?.trustedPersonInfo?.address
                            ?.street} ${outputValues?.trustedPersonInfo?.address
                            ?.house_number}, ${outputValues?.trustedPersonInfo
                            ?.address?.zip_code} ${outputValues
                            ?.trustedPersonInfo?.address?.city}, ${String(
                            translate(`global.ch`)
                          )}`,
                        })
                      )}
                    </Text>
                    <Text>
                      {String(
                        translate(
                          `powerOfAttorney.stepTwo.document.${
                            outputValues?.compensation === 'yes'
                              ? 'compensationDeclare'
                              : 'nonCompensationDeclare'
                          }`
                        )
                      )}
                    </Text>
                  </View>
                </View>
                <View style={{ gap: 12 }}>
                  <Text>
                    {String(
                      translate('powerOfAttorney.stepTwo.document.alternate')
                    )}
                  </Text>
                  <View style={{ width: '260px' }}>
                    <Text style={styles.underlinedText}>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.assetCare')
                      )}
                    </Text>
                    <Text style={styles.title}>
                      {
                        outputValues?.assetsAlternativeTrustedPersonInfo
                          ?.first_name
                      }{' '}
                      {
                        outputValues?.assetsAlternativeTrustedPersonInfo
                          ?.last_name
                      }
                    </Text>
                    <Text>
                      {String(
                        translate('livingWill.stepFive.document.address', {
                          address: `${outputValues
                            ?.assetsAlternativeTrustedPersonInfo?.address
                            ?.street} ${outputValues
                            ?.assetsAlternativeTrustedPersonInfo?.address
                            ?.house_number}, ${outputValues
                            ?.assetsAlternativeTrustedPersonInfo?.address
                            ?.zip_code} ${outputValues
                            ?.assetsAlternativeTrustedPersonInfo?.address
                            ?.city}, ${String(
                            translate(
                              `global.${outputValues?.assetsAlternativeTrustedPersonInfo?.address?.country.toLowerCase()}`
                            )
                          )}`,
                        })
                      )}
                    </Text>
                    <Text>
                      {String(
                        translate(
                          `powerOfAttorney.stepTwo.document.${
                            outputValues?.assets_power_of_attorney
                              ?.alternative_assets_compensation === 'yes'
                              ? 'compensationDeclare'
                              : 'nonCompensationDeclare'
                          }`
                        )
                      )}
                    </Text>
                  </View>
                  <View style={{ width: '260px' }}>
                    <Text style={styles.underlinedText}>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.personCare')
                      )}
                    </Text>
                    <Text style={styles.title}>
                      {outputValues?.alternativeTrustedPersonInfo?.first_name}{' '}
                      {outputValues?.alternativeTrustedPersonInfo?.last_name}
                    </Text>
                    <Text>
                      {String(
                        translate('powerOfAttorney.stepTwo.document.address', {
                          address: `${outputValues?.alternativeTrustedPersonInfo
                            ?.address?.street} ${outputValues
                            ?.alternativeTrustedPersonInfo?.address
                            ?.house_number}, ${outputValues
                            ?.alternativeTrustedPersonInfo?.address
                            ?.zip_code} ${outputValues
                            ?.alternativeTrustedPersonInfo?.address
                            ?.city}, ${String(
                            translate(
                              `global.${outputValues?.alternativeTrustedPersonInfo?.address?.country.toLowerCase()}`
                            )
                          )}`,
                        })
                      )}
                    </Text>
                    <Text>
                      {String(
                        translate(
                          `powerOfAttorney.stepTwo.document.${
                            outputValues?.alternative_compensation === 'yes'
                              ? 'compensationDeclare'
                              : 'nonCompensationDeclare'
                          }`
                        )
                      )}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.personCare'))}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.personCareDeclare1')
            )}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.personCareDeclare2')
            )}
          </Text>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.assetCare'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.assetCare1'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.assetCare2'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.assetCare3'))}
          </Text>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.repLegal'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.repLegal1'))}
          </Text>
          <Text>
            {String(translate('powerOfAttorney.stepTwo.document.repLegal2'))}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          {hasChildren && (
            <>
              <Text style={styles.title}>
                {String(
                  translate('powerOfAttorney.stepTwo.document.custodyOrder')
                )}
              </Text>
              <Text>
                {String(
                  translate('powerOfAttorney.stepTwo.document.childrenTrustee')
                )}
              </Text>
              <View>
                <Text>
                  {String(
                    translate('powerOfAttorney.stepTwo.document.primary')
                  )}
                </Text>
                <Text style={styles.title}>
                  {outputValues?.childrenTrusteeInfo?.first_name}{' '}
                  {outputValues?.childrenTrusteeInfo?.last_name}
                </Text>
                <Text>
                  {String(
                    translate('livingWill.stepFive.document.address', {
                      address: `${outputValues?.childrenTrusteeInfo?.address
                        ?.street} ${outputValues?.childrenTrusteeInfo?.address
                        ?.house_number}, ${outputValues?.childrenTrusteeInfo
                        ?.address?.zip_code} ${outputValues?.childrenTrusteeInfo
                        ?.address?.city}, ${String(
                        translate(
                          `global.${outputValues?.childrenTrusteeInfo?.address?.country.toLowerCase()}`
                        )
                      )}`,
                    })
                  )}
                </Text>
                <Text>
                  {String(
                    translate(
                      'powerOfAttorney.stepTwo.document.childrenTrusteeRelationship',
                      {
                        relation: translate(
                          `powerOfAttorney.stepTwo.document.relation.${children_power_of_attorney?.trusted_person_role_for_children}`
                        ),
                      }
                    )
                  )}
                </Text>
                <Text>
                  {String(
                    translate(
                      `powerOfAttorney.stepTwo.document.${
                        outputValues?.children_power_of_attorney
                          ?.children_compensation === 'yes'
                          ? 'compensationDeclare'
                          : 'nonCompensationDeclare'
                      }`
                    )
                  )}
                </Text>
              </View>
              <View>
                <Text>
                  {String(translate('powerOfAttorney.stepTwo.document.deputy'))}
                </Text>
                <Text style={styles.title}>
                  {outputValues?.childrenAlternativeTrusteeInfo?.first_name}{' '}
                  {outputValues?.childrenAlternativeTrusteeInfo?.last_name}
                </Text>
                <Text>
                  {String(
                    translate('livingWill.stepFive.document.address', {
                      address: `${outputValues?.childrenAlternativeTrusteeInfo
                        ?.address?.street} ${outputValues
                        ?.childrenAlternativeTrusteeInfo?.address
                        ?.house_number}, ${outputValues
                        ?.childrenAlternativeTrusteeInfo?.address
                        ?.zip_code} ${outputValues
                        ?.childrenAlternativeTrusteeInfo?.address
                        ?.city}, ${String(
                        translate(
                          `global.${outputValues?.childrenAlternativeTrusteeInfo?.address?.country.toLowerCase()}`
                        )
                      )}`,
                    })
                  )}
                </Text>
                <Text>
                  {String(
                    translate(
                      `powerOfAttorney.stepTwo.document.${
                        outputValues?.children_power_of_attorney
                          ?.alternative_compesation_for_children === 'yes'
                          ? 'compensationDeclare'
                          : 'nonCompensationDeclare'
                      }`
                    )
                  )}
                </Text>
              </View>
              <Text>
                {String(
                  translate(
                    'powerOfAttorney.stepTwo.document.childrenTrusteeDeclare'
                  )
                )}
              </Text>
            </>
          )}
          <Text style={styles.title}>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.releaseFromConfidentiality'
              )
            )}
          </Text>
          <Text>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.releaseFromConfidentialityDeclare'
              )
            )}
          </Text>
          <Text style={styles.title}>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.involvementOfAidsAndAssistants'
              )
            )}
          </Text>
          <Text>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.involvementOfAidsAndAssistantsDeclare'
              )
            )}
          </Text>
          <Text style={styles.title}>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.expensesAndCompensation'
              )
            )}
          </Text>
          <Text>
            a) {String(translate('powerOfAttorney.stepTwo.document.expenses'))}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.expensesDeclare')
            )}
          </Text>
          <Text>
            b){' '}
            {String(translate('powerOfAttorney.stepTwo.document.indemnities'))}
          </Text>
          <Text>
            {String(
              translate(
                indemnitiesPositive
                  ? 'powerOfAttorney.stepTwo.document.indemnitiesPositive'
                  : 'powerOfAttorney.stepTwo.document.indemnitiesNegative'
              )
            )}
          </Text>
        </View>
      </Page>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>
            {String(
              translate('powerOfAttorney.stepTwo.document.storageRegistration')
            )}
          </Text>
          <Text>
            a) {String(translate('powerOfAttorney.stepTwo.document.storage'))}
          </Text>
          <Text>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.storageRegistrationDeclare'
              )
            )}
          </Text>
          <Text>
            {String(
              translate(
                'powerOfAttorney.stepTwo.document.storageRegistrationDeclare2'
              )
            )}
          </Text>
          <Text>
            b){' '}
            {String(translate('powerOfAttorney.stepTwo.document.registration'))}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.registrationDeclare')
            )}
          </Text>
          <Text style={styles.title}>
            {String(
              translate('powerOfAttorney.stepTwo.document.applicableLaw')
            )}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.applicableLawDeclare')
            )}
          </Text>
          <Text>
            {user?.address?.zip_code}, {user?.address?.city}
          </Text>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.revocation'))}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.revocationDeclare')
            )}
          </Text>
          <Text style={styles.title}>
            {String(translate('powerOfAttorney.stepTwo.document.abilityToAct'))}
          </Text>
          <Text>
            {String(
              translate('powerOfAttorney.stepTwo.document.abilityToActDeclare')
            )}
          </Text>
          <Text>{`${user?.first_name} ${user?.last_name}`}</Text>
          <View
            style={{
              ...styles.pointParagraphs,
              gap: 20,
            }}
          >
            <Text>{address}</Text>
            <Text>{fDate(new Date(), 'dd. MMM yyyy', 'de')}</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PowerOfAttorneyDoc;
