import React from 'react';
// eslint-disable-next-line import/no-cycle
import About from './About/About';
import YourPowerOfAttorney from './YourPowerOfAttorney/YourPowerOfAttorney';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';

export const stepsData = [
  {
    label: 'Power of trust',
    title: 'What is about?',
    content: () => <About />,
  },
  {
    label: 'Your power of attorney',
    title: 'Now what?',
    content: (dbFormValues?: PowerOfAttorneyFormValues) => (
      <YourPowerOfAttorney outputValues={dbFormValues} />
    ),
  },
];

export const powerOfTrustQuestions = [
  {
    id: '1',
    value: 'trusted_person',
    compare: 'alternative_person',
  },
  {
    id: '2',
    value: 'compensation',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
  {
    id: '3',
    value: 'alternative_person',
    compare: 'trusted_person',
  },
  {
    id: '4',
    value: 'alternative_compensation',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
  {
    id: '5',
    value: 'has_children',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
  {
    id: '6',
    value: 'trusted_person_for_children',
    hasChildren: true,
    compare: 'alternative_person_for_children',
  },
  {
    id: '7',
    value: 'trusted_person_role_for_children',
    hasChildren: true,
    select: true,
  },
  {
    id: '8',
    value: 'children_compensation',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
    hasChildren: true,
  },
  {
    id: '9',
    value: 'alternative_person_for_children',
    hasChildren: true,
    compare: 'trusted_person_for_children',
  },
  {
    id: '10',
    value: 'alternative_compesation_for_children',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
    hasChildren: true,
  },
  {
    id: '11',
    value: 'assets_trusted_person',
    compare: 'alternative_assets_person',
  },
  {
    id: '12',
    value: 'assets_compensation',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
  {
    id: '13',
    value: 'alternative_assets_person',
    compare: 'assets_trusted_person',
  },
  {
    id: '14',
    value: 'alternative_assets_compensation',
    answers: [
      {
        value: 'yes',
      },
      {
        value: 'no',
      },
    ],
  },
];
